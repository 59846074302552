import Konva from "konva";
import { chain } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Image as KonvaImage } from "react-konva";
import useImage from "use-image";
import wash from "washyourmouthoutwithsoap";
import { useInterval } from "usehooks-ts";
import "./App.css";
import { CanvasMessageContainer, Overlay, ReloadButton } from "./Components";
import { Composition } from "./Composition";
import { Editor } from "./Editor";
import { Layout } from "./Layout";
import { defaultId, httpURL } from "./constants";
import { textSearch } from "./search";
import { randomPosition } from "./utils";
import { getPredefinedWords } from "./words";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Moderate } from "./Moderate";
import { InactivityMessage } from "./InactivityMessage";
import { Ad } from "./Ad";
import { Chat } from "./Chat";

function App() {
  const [words, setWords] = useState<Word[]>([]);
  const [images, setImages] = useState<Image[]>([]);
  const [resolution, setResolution] = useState(0.01);
  const [enableCanvas, setEnableCanvas] = useState(false);
  const [canvasDone, setCanvasDone] = useState(false);
  const [placeInContext, setPlaceInContext] = useState(false);
  const [selectedId, setSelectedId] = useState(defaultId);

  const activityTimeout = 60;
  const [activityTimer, setActivityTimer] = useState(activityTimeout);
  const reloadWindow = () => window.location.reload();
  const startedRef = useRef(false);

  const [language, setLanguage] = useState<"nl" | "en">("nl");

  useInterval(() => {
    setActivityTimer((activityTimer) => {
      if (!startedRef.current) {
        return activityTimer;
      }
      if (activityTimer === 0) {
        reloadWindow();
        return activityTimer;
      }
      return activityTimer - 1;
    });
  }, 1000);

  const onActivity = () => {
    setActivityTimer(activityTimeout);
    startedRef.current = true;
  };

  const onTextAnswer = (answer: string, language: "en" | "nl") => {
    if (answer === "1337 hack") {
      setEnableCanvas(true);
      setCanvasMessage("greetings 1337 hacker");
      return Promise.resolve();
    }

    async function runQuery() {
      // const words = answer.split(" ").filter((word) => word.length > 2);
      // const results = await Promise.all(words.map(textSearch));
      const results = await textSearch(answer, language);
      console.log({ results });
      const textItems = chain(results.texts)
        .map((text) => text.replace(/[^a-z0-9A-Z]+/g, " ").split(" "))
        .flatten()
        .compact()
        .filter((word) => !wash.check("en", word))
        .filter((word) => !wash.check("nl", word))
        .shuffle()
        .take(25)
        .concat(getPredefinedWords(10, language))
        .each(console.log)
        .map(
          (text): Word => ({
            text,
            position: randomPosition(),
            blurRadius: 0,
          })
        )
        // .flatten()
        // .compact()
        .value();

      const imageItems = results.images.map(
        (image): Image => ({ src: image, position: randomPosition() })
      );

      return textItems
        .map((item) => () =>
          new Promise<void>((resolve, reject) =>
            setTimeout(() => {
              setWords((items) => [...items, item]);
              resolve();
            }, 20)
          )
        )
        .reduce((cur, next) => cur.then(next), Promise.resolve())
        .then(() =>
          imageItems
            .map((item) => () =>
              new Promise<void>((resolve, reject) =>
                setTimeout(() => {
                  setImages((items) => [...items, item]);
                  resolve();
                }, 20)
              )
            )
            .reduce((cur, next) => cur.then(next), Promise.resolve())
        );
    }
    return runQuery().then(() => {
      // console.log("yesss");
    });
  };

  const onResolution = (resolution: number) => {
    if (resolution < 1) {
      return 0;
    }
    setResolution(resolution);
  };

  const [ads, setAds] = useState<JSX.Element[]>([]);
  const onAdsAmount = (adsAmount: number) => {
    setAds((ads) => {
      if (adsAmount < 2) return [];
      const numAds = 1 + Math.floor((adsAmount / 100) * 4);
      const adsCopy = [...ads];
      while (numAds !== adsCopy.length) {
        if (numAds > ads.length) {
          const newAd = <Ad index={adsCopy.length} />;
          adsCopy.push(newAd);
        } else {
          adsCopy.pop();
        }
      }
      return adsCopy;
    });
  };

  const onCensorshipAmount = (censorshipAmount: number) => {
    setWords((words) => {
      if (!words.length) return words;
      censorshipAmount = Math.floor((censorshipAmount / 100) * words.length);
      console.log({ censorshipAmount });
      let currentAmount = words.reduce(
        (acc, word) => acc + (word.blurRadius === 0 ? 0 : 1),
        0
      );
      const wordsCopy = [...words];
      let attempts = 0;
      console.log({ currentAmount, censorshipAmount });
      while (currentAmount !== censorshipAmount && attempts++ < 100) {
        wordsCopy[Math.floor(Math.random() * wordsCopy.length)].blurRadius =
          censorshipAmount > currentAmount ? 15 : 0;
        currentAmount = wordsCopy.reduce(
          (acc, word) => acc + (word.blurRadius === 0 ? 0 : 1),
          0
        );
      }
      // console.log("ye", { attempts, currentAmount, censorshipAmount });
      return wordsCopy;
    });
  };

  const [canvasMessage, setCanvasMessage] = useState("");
  const onCanvasStep = (canvasMessage: string) => {
    // console.log("enableinga canvaweas");
    setEnableCanvas(true);
    setCanvasMessage(canvasMessage);
  };

  const stageRef = useRef<Konva.Stage | null>(null);

  const onStageRef = (ref: React.MutableRefObject<Konva.Stage | null>) => {
    stageRef.current = ref.current;
  };

  function handleDone() {
    // console.log("done", stickerLayerRef);
    if (stageRef.current) {
      // console.log(stickerLayerRef.current);

      stageRef.current.toDataURL({
        y: window.innerHeight - 999,
        x: 33,
        width: window.innerWidth - 32 * 2 - 2,
        height: 520 - 32 * 2 - 2,
        callback: async (dataUrl) => {
          const response = await fetch(`${httpURL}/upload`, {
            method: "POST",
            body: dataUrl,
          });
          const responseText = await response.text();
          // console.log({ responseText });
          setSelectedId(responseText);
          setPlaceInContext(true);
          setResolution(0.01);
          setAds([]);
          setEnableCanvas(false);
          setWords([]);
          setImages([]);
          setCanvasDone(false);
        },
      });
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/moderate" element={<Moderate />} />
        <Route
          path="/view"
          element={
            <Layout>
              <Composition view={true} />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout>
              <Composition
                selectedId={selectedId}
                onDone={() => reloadWindow()}
                onActivity={onActivity}
              />
              {activityTimer < 11 && (
                <InactivityMessage
                  seconds={activityTimer}
                  onActivity={onActivity}
                />
              )}
              {!placeInContext && (
                <Overlay
                  onTouchStart={() => onActivity()}
                  onClick={() => onActivity()}
                >
                  <Editor
                    onStickerLayerRef={onStageRef}
                    words={words}
                    images={images}
                    enableCanvas={enableCanvas}
                    resolution={resolution}
                    ads={ads}
                    moveUp={canvasDone}
                  />
                  {Chat(
                    enableCanvas,
                    canvasDone,
                    onTextAnswer,
                    onResolution,
                    setEnableCanvas,
                    handleDone,
                    onAdsAmount,
                    onCensorshipAmount,
                    setLanguage,
                    setCanvasDone
                  )}
                  {/* {enableCanvas && !canvasDone && (
                    <CanvasMessageContainer>
                      <div>{canvasMessage}</div>
                      <button onClick={() => setCanvasDone(true)}>klaar</button>
                    </CanvasMessageContainer>
                  )} */}
                  <ReloadButton src="/img/reload.png" onClick={reloadWindow} />
                </Overlay>
              )}
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
