import Konva from "konva";
import React, { useEffect } from "react";
import { Image, Rect, Text } from "react-konva";
import { Sticker } from "./Sticker";
import useImage from "use-image";

export const ImageSticker: React.FC<{
  src: string;
  x: number;
  y: number;
  rotation: number;
  pixelSize: number;
}> = ({ src, x, y, rotation, pixelSize, ...props }) => {
  const imageRef = React.useRef<null | Konva.Image>(null);
  const [imageSize, setImageSize] = React.useState<{
    width?: number;
    height?: number;
  }>({ width: undefined, height: undefined });
  const [image, status] = useImage(src, "anonymous");

  useEffect(() => {
    if (imageRef.current) {
      console.log("stilca", pixelSize, imageRef.current?.getClientRect());
      imageRef.current?.cache({ offset: 10 });
    }
  }, [pixelSize]);

  React.useEffect(() => {
    // console.log("hoi", status);
    if (status === "loaded" && imageRef.current) {
      setImageSize({
        width: 200,
        height: (imageRef.current.height() / imageRef.current.width()) * 200,
      });
    }
  }, [status]);

  return (
    <Sticker x={x} y={y} rotation={rotation} {...props}>
      <Image
        ref={imageRef}
        image={image}
        offsetX={imageSize.width && imageSize.width / 2}
        offsetY={imageSize.height && imageSize.height / 2}
        width={imageSize.width}
        height={imageSize.height}
        filters={[Konva.Filters.Pixelate]}
        pixelSize={pixelSize * 0.25}
      />
    </Sticker>
  );
};
