import React from "react";
import useImage from "use-image";
import { Image } from "react-konva";

export const Ad: React.FC<{ index: number }> = ({ index }) => {
  const [image, status] = useImage(`/ads/${index}.png`);
  if (status !== "loaded") return null;
  return (
    <Image
      key={`ad-${index}`}
      image={image}
      width={200}
      height={200}
      x={Math.random() * (window.innerWidth - 32 * 2 - 200) + 32}
      y={Math.random() * (520 - 32 * 2 - 200) + window.innerHeight - 1000}
    />
  );
};
