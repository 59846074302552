import { useState } from "react";
import styled from "styled-components";
import { colors } from "./constants";

const ButtonContainer = styled.div`
  animation: 0.3s ease 0s 1 normal forwards running gZmgDU;
  background: rgb(0, 0, 0);
  border-radius: 18px 0px 0px 18px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  /* border: 1px solid ${colors.yellow}; */
  color: ${colors.white};
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  /* float: right; */
  /* max-width: 50%; */
  /* margin: -8px 0px 10px 46px; */
  overflow: hidden;
  position: relative;
  padding: 0 24px;
  transform: scale(0);
  transform-origin: left top;
`;

const MultiButtonContainer = styled.div`
  animation: 0.3s ease 0s 1 normal forwards running gZmgDU;
  background: rgb(0, 0, 0);
  border-radius: 0px 18px 18px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  color: ${colors.white};
  display: flex;
  justify-content: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* grid-auto-rows: 50px; */
  grid-gap: 10px;
  font-size: 14px;
  /* max-width: 50%; */
  /* margin: -8px 0px 10px 46px; */
  overflow: hidden;
  position: relative;
  padding: 0 24px;
  transform: scale(0);
  transform-origin: left top;
`;

const Button = styled.div`
  background: ${colors.blue};
  display: inline-flex;
  /* height: 28px; */
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
`;

export const ButtonStep: React.FC<
  any | { onClick: () => void; text: () => string }
> = ({ triggerNextStep, onClick, text = () => "klaar" }) => {
  return (
    <ButtonContainer>
      <Button
        onClick={() => {
          onClick();
          triggerNextStep();
        }}
      >
        {text()}
      </Button>
    </ButtonContainer>
  );
};

type Option = { text: string; value: string };
export const MultiButtonStep: React.FC<
  any | { onClick: (language: string) => void; options: Option[] }
> = ({ triggerNextStep, onClick, options }) => {
  return (
    <MultiButtonContainer>
      {(options as Option[]).map((option, i) => (
        <Button
          key={`button-step-${option.value}-${i}`}
          onClick={() => {
            onClick(option.value);
            triggerNextStep();
          }}
        >
          {option.text}
        </Button>
      ))}
    </MultiButtonContainer>
  );
};

const FloatingButtonContainer = styled.div`
  position: absolute;
  top: -192px;
  left: 0;
  background: ${colors.black};
  height: 192px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  &.done {
    position: static;
    button {
      display: none;
    }
  }
`;

const BotAvatar = styled.img`
  border: 1px solid #b5b0d7;
  height: 100px !important;
  width: 100px !important;
  padding: 10px !important;
  margin-right: 10px !important;
  border-radius: 10px;
`;

const Message = styled.div`
  border: 1px solid #b5b0d7;
  font-size: 21px !important;
  padding: 16px 32px !important;
  /* height: 130px; */
  border-radius: 10px;
  margin-right: 50px;
  color: white;
`;

const Arrow = styled.img`
  height: 70px;
  cursor: pointer;
`;

export const FloatingMessage: React.FC<
  any | { onClick: () => void; text: () => string }
> = ({ triggerNextStep, onClick, text = () => "klaar", ...props }) => {
  const [done, setDone] = useState(false);
  return (
    <FloatingButtonContainer className={done ? "done" : ""} {...props}>
      <BotAvatar src="/img/bot.png" />
      <Message>{text()}</Message>
      <Arrow
        src="/img/arrow.png"
        onClick={() => {
          setDone(true);
          onClick();
          triggerNextStep && triggerNextStep();
        }}
      />
    </FloatingButtonContainer>
  );
};
