import { Power4, gsap } from "gsap";
import { sample } from "lodash";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import styled from "styled-components";
import { FullScreenContainer } from "./Components";
import { colors, enableRenderer, httpURL } from "./constants";
import { socket as socketIo } from "./socket";
import bg from "./img/bg.png";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { FloatingMessage } from "./ButtonStep";

gsap.registerPlugin(ScrollToPlugin);

const FullScreenCompositionContainer = styled(FullScreenContainer)`
  overflow: hidden;
`;

const width = 10000;
const height = 10000;

const Container = styled.div`
  background: ${colors.black};
  height: ${height}px;
  width: ${width}px;
`;

const ZoomContainer = styled.div`
  position: absolute;
  inset: 0;
`;
const ScrollContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const InnerContainer = styled.div`
  height: ${height}px;
  width: ${width}px;
`;

const ContainerClipBox = styled.div`
  position: absolute;
  height: ${height}px;
  width: ${width}px;
  position: absolute;
`;

const Scanlines = styled(FullScreenContainer)`
  position: fixed;
  height: ${height}px;
  width: ${width}px;
  background-image: url("${bg}");
  background-repeat: repeat;
  position: absolute;
  transform: scale(1.5);
  transform-origin: center;
`;

const AnimationContainer = styled.div`
  position: absolute;
  inset: 0;
`;

const DoneContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 40px;
  background: ${colors.black};
  padding: 0 24px;
  /* z-index: 999; */
`;

const DoneButton = styled.button`
  margin-top: 16px;
  font-size: 40px;
`;

const Box = styled.img`
  position: absolute;
  background: rgba(255, 255, 255, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  &.selected {
    box-shadow: 0px 0px 180px rgba(255, 255, 255, 0.2);
  }
`;

export const Composition: React.FC<{
  selectedId?: string;
  onDone?: () => void;
  view?: boolean;
  onActivity?: () => void;
}> = ({ selectedId, onDone, view, onActivity }) => {
  const fullscreenCompositionContainerRef = useRef<null | HTMLDivElement>(null);
  const animationContainerRef = useRef<null | HTMLDivElement>(null);
  const zoomContainerRef = useRef<null | HTMLDivElement>(null);
  const scrollContainerRef = useRef<null | HTMLDivElement>(null);
  const innerContainerRef = useRef<null | HTMLDivElement>(null);
  const [elements, setElements] = useState<BoxElement[]>([]);
  const [socket, setSocket] = useState<null | Socket>(null);
  const knownIdsRef = useRef<null | string[]>(null);
  const viewQueueRef = useRef<string | null>(null);
  useEffect(() => {
    const server = socketIo();
    server.on("elements", (data: any) => {
      setElements(data.filter((item: any) => item.type === "box"));
    });
    setSocket(server);
    return () => {
      server.offAny();
      server.close();
    };
  }, []);

  // useEffect(() => {
  //   if (view) {
  //     if (knownIdsRef.current === null) {
  //       knownIdsRef.current = elements.map((element) => element.id);
  //     } else {
  //       for (const element of elements) {
  //         if (!knownIdsRef.current.includes(element.id)) {
  //           knownIdsRef.current.push(element.id);
  //           viewQueueRef.current = element.id;
  //         }
  //       }
  //     }
  //   }
  // }, [elements, view]);

  const focusTimeout = useRef<number | null>(null);

  const elementsRef = useRef<BoxElement[]>([]);
  useEffect(() => {
    elementsRef.current = elements;
    snapToSelectedElement(false);
  }, [elements]);

  const comp = useRef();
  const comp2 = useRef();
  const selectedIdRef = useRef("");

  const selectedElementRef = useRef<BoxElement | null>(null);

  const findElement = (selectedId: string) => {
    return new Promise<BoxElement | null>((resolve, reject) => {
      // console.log("tryna find da element");
      const foundElement = !view
        ? elementsRef.current.find((element) => element.id === selectedId)
        : viewQueueRef.current === null
        ? sample(elementsRef.current)
        : elementsRef.current.find(
            (element) => element.id === viewQueueRef.current
          );
      selectedElementRef.current = foundElement ? foundElement : null;
      viewQueueRef.current = null;
      resolve(foundElement ? foundElement : null);
    });
  };

  const scrollFunc = () => {
    if (!view) return;
    findElement(selectedIdRef.current).then((element) => {
      if (element) {
        const scale =
          Math.random() < 0.5
            ? 0.3 + Math.random() * 0.3
            : 0.9 + Math.random() * 0.2;
        console.log("nah", { width: window.innerWidth });
        if (scrollContainerRef.current) {
          gsap.to(zoomContainerRef.current, {
            duration: 4,
            scale,
            ease: Power4.easeInOut,
          });
          gsap
            .to(scrollContainerRef.current, {
              duration: 4,
              scrollTo: {
                x: (element.x - window.innerWidth / 2) * scale,
                y: (element.y - window.innerHeight / 2) * scale,
              },
              ease: Power4.easeInOut,
            })
            .then(() => {
              if (view) {
                setTimeout(scrollFunc, 8000);
              }
            });
        }
      } else {
        setTimeout(scrollFunc, 1000);
      }
    });
  };

  useEffect(() => {
    if (!selectedId) return;
    selectedIdRef.current = selectedId;
  }, [selectedId]);

  useEffect(() => {
    if (view) {
      scrollFunc();
    }
  }, [view]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (!view) return;
      // const scaleFunc = () => {
      //   console.log("scale", selectedElementRef.current === null);
      //   gsap
      //     .to(zoomContainerRef.current, {
      //       scale: true ? 1 : 1 + Math.random() * 0.1,
      //       duration:
      //         selectedElementRef.current === null ? 1 : 1 + Math.random() * 1,
      //       ease: Power4.easeInOut,
      //     })
      //     .then(scaleFunc);
      // };
      const translateXFunc = () => {
        gsap
          .to(animationContainerRef.current, {
            translateX: (Math.random() * 2 - 1) * 100,
            duration: 3 + Math.random() * 10,
            ease: Power4.easeInOut,
          })
          .then(translateXFunc);
      };
      const translateYFunc = () => {
        gsap
          .to(animationContainerRef.current, {
            translateY: (Math.random() * 2 - 1) * 100,
            duration: 3 + Math.random() * 10,
            ease: Power4.easeInOut,
          })
          .then(translateYFunc);
      };
      translateXFunc();
      translateYFunc();
      // scaleFunc();
    }, comp);
    return () => ctx.revert();
  }, []);

  // useLayoutEffect(() => {
  //   const ctx = gsap.context(() => {
  //     scrollFunc();
  //     return () => ctx.revert(); // cleanup
  //   }, comp2);
  // }, []);

  function handleTouchStartBox(event: React.TouchEvent<HTMLDivElement>) {
    // console.log("touchStart", event);
    //// console.log("Send mouse: " + mouseX + " " + mouseY);
    let data = {
      changedTouches: [
        {
          pageX:
            event.touches[0].pageX +
            fullscreenCompositionContainerRef.current!.scrollLeft,
          pageY:
            event.touches[0].pageY +
            fullscreenCompositionContainerRef.current!.scrollTop,
          identifier: 1,
        },
      ],
    };

    // console.log(data);
    if (!socket) return;
    socket.emit("mousedown", data); //Send to server
    event.stopPropagation();
  }

  async function snapToSelectedElement(retry = true) {
    let element: BoxElement | null | undefined;

    if (retry) {
      element = await findElement(selectedIdRef.current);
    } else {
      element = elementsRef.current.find(
        (element) => element.id === selectedIdRef.current
      );
    }
    if (element) {
      scrollContainerRef.current!.scrollTo({
        left: element.x - window.innerWidth / 2,
        top: element.y - window.innerHeight / 2,
      });
    }
  }

  async function handleTouchMoveBox(event: React.TouchEvent<HTMLDivElement>) {
    let data = {
      changedTouches: [
        {
          pageX:
            event.touches[0].pageX + scrollContainerRef.current!.scrollLeft,
          pageY: event.touches[0].pageY + scrollContainerRef.current!.scrollTop,
          identifier: 1,
        },
      ],
    };

    // console.log(
    //   "touchmove",
    //   data.changedTouches[0].pageX,
    //   data.changedTouches[0].pageY,
    //   data
    // );

    if (!socket) return;
    socket.emit("mousemove", data); //Send to server
    event.stopPropagation();
  }

  function handleTouchEndBox(event: React.TouchEvent<HTMLDivElement>) {
    let data = {
      changedTouches: [
        {
          pageX:
            event.changedTouches[0].pageX +
            scrollContainerRef.current!.scrollLeft,
          pageY:
            event.changedTouches[0].pageY +
            scrollContainerRef.current!.scrollTop,
          identifier: 1,
        },
      ],
    };

    // scrollContainerRef.current!.scrollTo({
    //   left:
    //     event.changedTouches[0].pageX -
    //     window.innerWidth / 2 +
    //     scrollContainerRef.current!.scrollLeft,
    //   top:
    //     event.changedTouches[0].pageY -
    //     window.innerHeight / 2 +
    //     scrollContainerRef.current!.scrollTop,
    //   behavior: "smooth",
    // });

    // console.log(data);
    if (!socket) return;
    socket.emit("mouseup", data); //Send to server

    event.stopPropagation();
  }
  function handleMouseDownBox(event: React.MouseEvent<HTMLDivElement>) {
    // console.log("mouseDown", event);
    //// console.log("Send mouse: " + mouseX + " " + mouseY);
    let data = {
      changedTouches: [
        {
          pageX: event.pageX + scrollContainerRef.current!.scrollLeft,
          pageY: event.pageY + scrollContainerRef.current!.scrollTop,
          identifier: 1,
        },
      ],
    };

    // console.log(data);
    if (!socket) return;
    socket.emit("mousedown", data); //Send to server
    event.stopPropagation();
  }

  function handleDragStartBox(event: React.DragEvent<HTMLImageElement>) {
    // console.log("dragstart");
    var img = new Image();
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
    event.dataTransfer.setDragImage(img, 0, 0);
  }

  function handleDragBox(event: React.DragEvent<HTMLDivElement>) {
    console.log("dragbox", event.pageY, scrollContainerRef.current!.scrollTop);
    let data = {
      changedTouches: [
        {
          pageX: event.pageX + scrollContainerRef.current!.scrollLeft,
          pageY: event.pageY + scrollContainerRef.current!.scrollTop,
          identifier: 1,
        },
      ],
    };
    // console.log(data);
    if (!socket || (event.pageX === 0 && event.pageY === 0)) return;
    console.log(data.changedTouches[0]);
    socket.emit("mousemove", data); //Send to server
    event.stopPropagation();
  }

  function handleMouseUpBox(event: React.MouseEvent<HTMLDivElement>) {
    // console.log("handlemouseupbox");
    let data = {
      changedTouches: [
        {
          pageX: event.pageX + scrollContainerRef.current!.scrollLeft,
          pageY: event.pageY + scrollContainerRef.current!.scrollTop,
          identifier: 1,
        },
      ],
    };

    // console.log(data);
    if (!socket) return;
    socket.emit("mouseup", data); //Send to server
    event.stopPropagation();
  }

  return (
    <>
      <FullScreenCompositionContainer
        ref={fullscreenCompositionContainerRef}
        onTouchStart={() => onActivity && onActivity()}
        onClick={() => onActivity && onActivity()}
      >
        <Container>
          <ContainerClipBox>
            <ScrollContainer ref={scrollContainerRef}>
              <AnimationContainer ref={animationContainerRef}>
                <ZoomContainer ref={zoomContainerRef}>
                  <Scanlines />
                  <InnerContainer>
                    {enableRenderer && (
                      <FullScreenContainer></FullScreenContainer>
                    )}
                    {elements.map((element) =>
                      selectedId === element.id ? (
                        <Box
                          className="box selected"
                          onTouchMove={handleTouchMoveBox}
                          onTouchStart={handleTouchStartBox}
                          onTouchEnd={handleTouchEndBox}
                          onMouseDown={handleMouseDownBox}
                          onMouseUp={handleMouseUpBox}
                          onDrag={handleDragBox}
                          onDragEnd={handleMouseUpBox}
                          onDragStartCapture={handleDragStartBox}
                          draggable={true}
                          key={`element-${element.id}`}
                          style={{
                            top: element.y - element.h / 2,
                            left: element.x - element.w / 2,
                            height: element.h,
                            width: element.w,
                            transform: `rotate(${Math.round(
                              element.angle * 100
                            ) / 100}rad)`,
                          }}
                          src={`${httpURL}/images/${element.id}.png`}
                        />
                      ) : (
                        <Box
                          className="box"
                          key={`element-${element.id}`}
                          style={{
                            top: element.y - element.h / 2,
                            left: element.x - element.w / 2,
                            height: element.h,
                            width: element.w,
                            transform: `rotate(${Math.round(
                              element.angle * 100
                            ) / 100}rad)`,
                          }}
                          src={`${httpURL}/images/${element.id}.png`}
                        />
                      )
                    )}
                  </InnerContainer>
                </ZoomContainer>
              </AnimationContainer>
            </ScrollContainer>
          </ContainerClipBox>
        </Container>
      </FullScreenCompositionContainer>
      {!view && (
        <DoneContainer>
          {!!selectedId && (
            <FloatingMessage
              style={{ position: "static" }}
              text={() =>
                "geef je collage een goed plekje tussen de anderen door hem te verslepen"
              }
              onClick={() => onDone && onDone()}
            />
          )}
        </DoneContainer>
      )}
    </>
  );
};
