export const randomPosition = () => {
  let position = {
    x: Math.random() * window.innerWidth,
    y: Math.random() * window.innerHeight,
  };
  // chat box
  if (position.y > window.innerHeight - 1000) {
    position = randomPosition();
  }
  return position;
};
