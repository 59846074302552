import React from "react";
import styled from "styled-components";
import { getCountdownMessage } from "./steps";

const MessageBox = styled.div`
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  border-radius: 25px;
  background: black;
  border: 2px solid white;
  color: white;
`;

export const InactivityMessage: React.FC<{
  seconds: number;
  onActivity: () => void;
}> = ({ seconds, onActivity }) => {
  return (
    <MessageBox onTouchStart={() => onActivity()} onClick={() => onActivity()}>
      {getCountdownMessage(seconds)}
    </MessageBox>
  );
};
