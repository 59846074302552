import React, { PropsWithChildren, useRef, useState } from "react";
import { Group, Label, Rect, Tag, Text } from "react-konva";
import Hammer from "./Hammer";
import Konva from "konva";

export const Sticker: React.FC<PropsWithChildren & {
  x: number;
  y: number;
  rotation: number;
}> = ({ children, x, y, rotation: initialRotation, ...props }) => {
  const [startRotation, setStartRotation] = useState(initialRotation);
  const [startScale, setStartScale] = useState(1);
  const [rotation, setRotation] = useState(initialRotation);
  const [scale, setScale] = useState(1);
  const [draggable, setDraggable] = useState(true);

  const previousRotationRef = useRef(initialRotation);

  const handleRotationStart = (ev: any) => {
    setDraggable(false);
    setStartScale((startScale) => startScale - ev.scale);
    setStartRotation((startRotation) => startRotation - ev.rotation);
  };
  const handleRotation = (ev: any) => {
    const newRotation = ev.rotation + startRotation;
    console.log(
      "handleRotation ",
      previousRotationRef.current,
      newRotation,
      Math.abs(previousRotationRef.current - newRotation)
    );
    if (Math.abs(previousRotationRef.current - newRotation) < 50) {
      setRotation(newRotation);
      setScale(ev.scale + startScale);
      previousRotationRef.current = newRotation;
    }
  };
  const handleRotationEnd = (ev: any) => {
    setDraggable(true);
    setStartRotation(rotation);
    setStartScale(scale);
  };

  return (
    <Hammer
      onRotateStart={handleRotationStart}
      onRotate={handleRotation}
      onRotateEnd={handleRotationEnd}
      onRotateCancel={handleRotationEnd}
      {...props}
    >
      <Group
        x={x}
        y={y}
        scaleX={scale}
        scaleY={scale}
        rotation={rotation}
        draggable={draggable}
      >
        {children}
      </Group>
    </Hammer>
  );
};
