import React, { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import styled from "styled-components";
import { Layout } from "./Layout";
import { httpURL } from "./constants";
import { socket as socketIo } from "./socket";

const Container = styled.div`
  padding: 0px 12px 64px;
`;

const ElementContainer = styled.div`
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;
  padding: 12px 24px;
  background: #dddddd;
`;

const ElementImage = styled.img`
  max-width: 100%;
  margin: 64px 0px 24px;
`;

const Button = styled.button`
  font-size: 16px;
  padding: 8px;
`;

export const Moderate: React.FC<{}> = () => {
  const [elements, setElements] = useState<BoxElement[]>([]);
  useEffect(() => {
    const server = socketIo();
    server.on("elements", (data: any) => {
      setElements(
        data
          .filter((item: any) => item.type === "box")
          .sort((a: any, b: any) => b.added - a.added)
      );
    });
    return () => {
      server.offAny();
      server.close();
    };
  }, []);

  const deleteItem = async (id: string) => {
    const response = await fetch(`${httpURL}/delete?id=${id}`, {
      method: "GET",
    });
    const responseText = await response.text();
    console.log({ responseText });
  };

  return (
    <Layout>
      <Container>
        <h1>internew moderation</h1>
        {elements.map((element) => (
          <ElementContainer key={`element-${element.id}`}>
            <div>
              {new Date(element.added!).toLocaleDateString()}{" "}
              {new Date(element.added!).toLocaleTimeString()}
            </div>
            <ElementImage src={`${httpURL}/images/${element.id}.png`} />
            <hr />
            <Button onClick={() => deleteItem(element.id)}>🗑️</Button>
          </ElementContainer>
        ))}
      </Container>
    </Layout>
  );
};
