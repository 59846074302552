import { ButtonStep, FloatingMessage, MultiButtonStep } from "./ButtonStep";
import { SliderStep } from "./SliderStep";

export const getCountdownMessage = (seconds: number) =>
  `Raak het scherm aan om verder te gaan. Anders wordt de applicatie over ${seconds} seconden herstart.`;

export function getSteps({
  onTextAnswer,
  onResolution,
  setEnableCanvas,
  handleDone,
  onAdsAmount,
  onCensorshipAmount,
  setLanguage: setLanguageState,
  setCanvasDone,
}: {
  onTextAnswer: (answer: string, language: "en" | "nl") => void;
  onResolution: (resolution: number) => void;
  onAdsAmount: (adsAmount: number) => void;
  onCensorshipAmount: (censorshipAmount: number) => void;
  setEnableCanvas: (enable: boolean) => void;
  handleDone: () => void;
  setLanguage: (language: "nl" | "en") => void;
  setCanvasDone: (done: boolean) => void;
}) {
  let language: "en" | "nl" = "nl";
  const setLanguage = (newLanguage: "en" | "nl") => {
    console.log("setLanguage", newLanguage);
    setLanguageState(newLanguage);
    language = newLanguage;
  };

  //
  // vanaf hier
  //
  //
  //

  let variabelWoordPositief = "";
  let variabelWoordNegatief = "";
  let username = "";
  return [
    {
      message: "Hi!",
    },
    {
      waitAction: true,
      component: (
        <MultiButtonStep
          onClick={(value: string) => setLanguage(value as "en" | "nl")}
          options={[
            { text: "Nederlands 🇳🇱", value: "nl" },
            { text: "English 🇬🇧", value: "en" },
          ]}
        />
      ),
    },
    {
      message: () =>
        language === "nl"
          ? "Je hebt al veel werken bekeken van kunstenaars van vroeger en nu. Elke kunstenaar heeft een eigen idee over technologieën als computers en het internet."
          : "You've already seen a lot of art made by artists from then and now. Each artist has their own idea about using computers or the internet.",
    },
    {
      message: () =>
        language === "nl"
          ? "Het internet is nu overal en speelt een enorme rol in ons dagelijks leven. Maar bevalt het eigenlijk wel? Wat vind jij zelf?"
          : "Today, the internet is everywhere. It’s a huge part of everyday life. But do you like it? What do you think?",
      delay: 3000,
    },
    {
      message: () =>
        language === "nl"
          ? "Geef je mening en ontwerp mee aan een nieuw internet: het Internew."
          : "Give us your feedback and together we’ll design a new internet: the Internew.",
      delay: 3000,
    },
    {
      message: () =>
        language === "nl"
          ? "Hoe wil je het liefst genoemd worden?"
          : "What’s your screen name going to be?",
      delay: 6000,
    },
    {
      user: true,
      validator: (value: string) => {
        username = value;
        return true;
      },
    },
    {
      message: () =>
        language === "nl"
          ? `Hoi ${username}, wat vind je leuk aan het internet van nu en wil je graag meenemen naar het Internew? `
          : `Hi ${username}, what do you like about today's internet that you'd like to have in the Internew? `, // vergeet niet () => als je een variabele gebruikt
    },
    {
      user: true,
      // de meest basic validator die gaat zoeken op basis van tekst
      validator: (value: string) => {
        onTextAnswer(value, language);
        variabelWoordPositief = value;
        return true;
      },
    },
    {
      message: () => (language === "nl" ? "Klinkt leuk zeg!" : "Sounds great!"),
      delay: 1000,
    },
    {
      message: () =>
        language === "nl"
          ? "Zie je trouwens woorden en beelden binnenstromen? Deze worden aan je voorgesteld door een bijzondere zoekmachine. Door de manier waarop zoekmachines werken kunnen wij niet helemaal controleren wat de uitkomsten zijn. Zie je iets dat niet bij een gezond, gelijkwaardig en veilig internet past? Laat het dan een medewerker weten!"
          : "Can you see a stream of words and images? They’re being shown to you by a special search engine. Because of the way search engines work, we don’t have much control over what we get to see. Have you spotted something that doesn’t belong in a healthy, fair and safe internet? If you have, tell one of our team!",
      delay: 1000,
    },
    {
      message: () =>
        language === "nl"
          ? "Wat vind je irritant aan het internet en wil je niet meenemen naar het Internew?"
          : "What irritates you about the internet, that you don’t want in the Internew?",
      delay: 10000,
    },
    {
      user: true,
      validator: (value: string) => {
        onTextAnswer(value, language);
        variabelWoordNegatief = value;
        return true;
      },
    },
    {
      message: () =>
        language === "nl"
          ? `Dank voor je antwoorden, ${username}, Nu kun je jouw Internew ontwerpen. Schuif, draai en zoom met de verzamelde woorden en beelden en laat zien wat voor jou belangrijk is.`
          : `Thanks for your answers, ${username} Now you can design your Internew. Slide, turn and zoom the words and images you’ve collected, and show what you think is important.`, // vergeet niet () => als je een variabele gebruikt
    },
    {
      message: () =>
        language === "nl"
          ? `${username}, hoe maken we van het Internew een plek met ${variabelWoordPositief}, maar zonder ${variabelWoordNegatief}?`
          : `${username}, how do we make the Internew a place with ${variabelWoordPositief}, but without ${variabelWoordNegatief}`,
    },
    {
      waitAction: true,
      component: (
        <ButtonStep
          text={() => (language === "nl" ? "klaar voor" : "ready")}
          onClick={() => {
            setEnableCanvas(true);
          }}
        />
      ),
    },

    {
      waitAction: true,
      component: (
        <FloatingMessage
          onClick={() => {
            setCanvasDone(true);
          }}
          text={() =>
            language === "nl"
              ? `${username}, hoe maken we van het Internew een plek met ${variabelWoordPositief}, maar zonder ${variabelWoordNegatief}? Geef je antwoord vorm met tekst en beeld in de gele rechthoek`
              : `${username}, how do we make the Internew a place with ${variabelWoordPositief}, but without ${variabelWoordNegatief}`
          }
        />
      ),
    },

    {
      message: () =>
        language === "nl"
          ? "Wat een mooi ontwerp! Maar je bent nog niet klaar, er zijn nog drie vraagstukken die opgelost moeten worden. Elk antwoord draagt bij aan jouw ideale Internew."
          : "That’s a great design! But you’re not done yet. There are three more questions for you to answer. Every answer adds a little more to your ideal Internew.",
      delay: 1000,
    },
    {
      message: () =>
        language === "nl"
          ? "Het internet stoot vier procent van alle CO2 in de wereld uit, dat is net zoveel als alle vliegtuigen in een heel jaar. Niet goed voor het klimaat dus, wat betekent dit voor jouw Internew?"
          : "The internet produces four percent of the world’s CO2. That’s the same amount as all the airplanes in an entire year. And that’s not good for the climate. How will this affect your Internew? ",
      delay: 3000,
    },
    {
      message: () =>
        language === "nl"
          ? "Video’s en afbeeldingen verbruiken de meeste energie. Ga je voor supersnel of duurzaam internet?"
          : "Videos and images use the most energy. Which will you choose – a superfast or climate-friendly internet?",
      delay: 5000,
    },
    {
      waitAction: true,
      component: (
        <SliderStep
          onChange={onResolution}
          minLabel={() => (language === "nl" ? "supersnel" : "superfast")}
          maxLabel={() => (language === "nl" ? "duurzaam" : "climate-friendly")}
        />
      ),
    },
    {
      message: () =>
        language === "nl"
          ? "Het is belangrijk om te kunnen zeggen wat je vindt, maar soms kan dit mensen kwetsen."
          : "Being able to say what you think is important, but can sometimes hurt other people.",
    },
    {
      message: () =>
        language === "nl"
          ? "Wil je dat er regels worden bedacht die alles in de gaten houden en bepalen wat je mag zeggen op het Internew?"
          : "Are people the boss and free to say whatever they like?",
      delay: 5000,
    },
    {
      message: () =>
        language === "nl"
          ? "Of zijn de mensen de baas en vrij om hun mening te uiten?"
          : "Or do you want your Internew to have rules that keep an eye on things and tell you what to say?",
      delay: 1000,
    },
    {
      waitAction: true,
      component: (
        <SliderStep
          onChange={onCensorshipAmount}
          minLabel={() =>
            language === "nl"
              ? "vrijheid van meningsuiting"
              : "freedom of expression"
          }
          maxLabel={() => (language === "nl" ? "regels" : "rules")}
        />
      ),
    },
    {
      message: () =>
        language === "nl"
          ? "Ik snap het. Nu nog een laatste vraagstuk: geld. Het kost geld om het Internew te laten werken."
          : "Running the Internew costs money.",
    },
    {
      message: () =>
        language === "nl"
          ? "Waar moet dit geld vandaan komen?"
          : "Where should that money come from?",
      delay: 5000,
    },
    {
      message: () =>
        language === "nl"
          ? "Wil jij meebetalen aan Internew, of heb je liever een gratis Internew vol reclames? "
          : "Should people pay to use the Internew, or should it be free and full of ads?",
      delay: 1000,
    },
    {
      waitAction: true,
      component: (
        <SliderStep
          onChange={onAdsAmount}
          minLabel={() =>
            language === "nl" ? "meebetalen" : "pay contribution"
          }
          maxLabel={() => (language === "nl" ? "reclames" : "ads")}
        />
      ),
    },
    {
      message: () =>
        language === "nl"
          ? `Bedankt voor al je antwoorden ${username}. Geef jouw creatie een plek tussen de andere ontwerpen. Zo bouwen we samen het Internew!`
          : `Thanks for all your answerst ${username} Find a place for your design among the others. So we can design the Internew together!`,
    },
    {
      waitAction: true,
      component: (
        <ButtonStep
          text={() => (language === "nl" ? "klaar" : "done")}
          onClick={() => {
            handleDone();
          }}
        />
      ),
    },
    {
      user: true,
    },

    //
    //
    // tot hier
    //
    //
    //
  ].map((step, i, arr) => ({
    ...step,
    id: i.toString(),
    trigger: i < arr.length - 1 ? (i + 1).toString() : "0",
    // options: step.options
    //   ? step.options.map((option) => ({
    //       ...option,
    //       trigger: (i + 1).toString(),
    //     }))
    //   : undefined,
  }));
}
