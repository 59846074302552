import Konva from "konva";
import React, { useEffect, useRef } from "react";
import { Group, Image, Layer, Rect, Stage, Text } from "react-konva";
import { ImageSticker } from "./ImageSticker";
import { TextSticker } from "./TextSticker";
import { EditorContainer } from "./Components";
import { colors } from "./constants";

export const Editor: React.FC<{
  onStickerLayerRef: (ref: React.MutableRefObject<Konva.Stage | null>) => void;
  words: Word[];
  images: Image[];
  enableCanvas: boolean;
  resolution: number;
  ads: JSX.Element[];
  moveUp: boolean;
}> = ({
  onStickerLayerRef,
  words,
  images,
  enableCanvas,
  resolution,
  ads,
  moveUp,
}) => {
  const ImageGroupRef = useRef<null | Konva.Group>(null);
  const stageRef = useRef<null | Konva.Stage>(null);

  useEffect(() => {
    onStickerLayerRef(stageRef);
  }, [onStickerLayerRef]);

  return (
    <EditorContainer className={moveUp ? "move-up" : ""}>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        ref={stageRef}
      >
        <Layer hitGraphEnabled={true}>
          <Group>
            {images.map(({ src, position }, i) => {
              return (
                <ImageSticker
                  key={`sticker-image-${i}`}
                  src={src}
                  x={position.x}
                  y={position.y}
                  rotation={Math.random() * 120 - 60}
                  pixelSize={resolution}
                />
              );
            })}
          </Group>
        </Layer>
        <Layer hitGraphEnabled={true}>
          {words.map(({ text, position, blurRadius }, i) => {
            return (
              <TextSticker
                key={`sticker-text-${i}`}
                text={text}
                x={position.x}
                y={position.y}
                rotation={Math.random() * 120 - 60}
                blurRadius={blurRadius}
              />
            );
          })}
        </Layer>
        <Layer hitGraphEnabled={false}>{ads}</Layer>
        {enableCanvas && (
          <Layer hitGraphEnabled={false}>
            <Rect
              stroke={colors.yellow}
              strokeWidth={1}
              y={window.innerHeight - 1000}
              x={32}
              width={window.innerWidth - 32 * 2}
              height={520 - 32 * 2}
            />
          </Layer>
        )}
      </Stage>
    </EditorContainer>
  );
};
