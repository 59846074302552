import React, { Fragment, PropsWithChildren } from "react";
import { Helmet } from "react-helmet";
import { LayoutContainer } from "./Components";

export const Layout: React.FC<PropsWithChildren> = (props) => {
  return (
    <Fragment>
      <Helmet>
        <title>hoi</title>
        <script src="https://cdn.rawgit.com/hammerjs/touchemulator/master/touch-emulator.js"></script>
        <script src="js/gestures.js"></script>
      </Helmet>
      <LayoutContainer className="app">{props.children}</LayoutContainer>
    </Fragment>
  );
};
