import Konva from "konva";
import React from "react";
import { Group, Layer, Rect, Text } from "react-konva";
import { Sticker } from "./Sticker";

export const TextSticker: React.FC<{
  text: string;
  x: number;
  y: number;
  rotation: number;
  blurRadius: number;
}> = ({ text, x, y, rotation, blurRadius, ...props }) => {
  const textRef = React.useRef<null | Konva.Text>(null);
  const [textSize, setTextSize] = React.useState({ width: 0, height: 0 });
  const [rectSize, setRectSize] = React.useState({ width: 0, height: 0 });
  React.useEffect(() => {
    if (textRef.current) {
      setTextSize({
        width: textRef.current.width(),
        height: textRef.current.height(),
      });
      setRectSize({
        width: textRef.current.width() + 16,
        height: textRef.current.height() + 8,
      });
    }
  }, []);
  const groupRef = React.useRef<null | Konva.Group>(null);
  React.useEffect(() => {
    if (groupRef.current) {
      groupRef.current?.cache({ offset: 10 });
    }
  }, [blurRadius, rectSize]);

  return (
    <Sticker x={x} y={y} rotation={rotation} {...props}>
      <Group
        filters={[Konva.Filters.Blur]}
        blurRadius={blurRadius}
        ref={groupRef}
      >
        <Rect
          width={rectSize.width + 50}
          height={rectSize.height + 50}
          offsetX={(rectSize.width + 50) / 2}
          offsetY={(rectSize.height + 50) / 2}
        />
        <Rect
          width={rectSize.width}
          height={rectSize.height}
          fill="#000000aa"
          offsetX={rectSize.width / 2}
          offsetY={rectSize.height / 2}
        />
        <Text
          ref={textRef}
          text={text}
          align="center"
          fill="#ffffff"
          fontFamily="HALTwinsLight"
          offsetX={textSize.width / 2}
          offsetY={textSize.height / 2}
          fontSize={28}
        />
      </Group>
    </Sticker>
  );
};
