import { sample } from "lodash";

const predefinedWords = (language: "en" | "nl") =>
  language === "nl"
    ? [
        "is",
        "met",
        "internet",
        "website",
        "zeggen",
        "schrijven",
        "mogen",
        "moeten",
        "krijgen",
        "hebben",
        "willen",
        "wensen",
        "weten",
        "zeggen",
        "moet",
        "mag",
        "krijgt",
        "iedereen",
        "niemand",
        "altijd",
        "nooit",
        "je",
        "kan",
        "de",
        "het",
        "een",
        "elke",
        "geen",
        "maken",
        "💅",
        "🤳",
        "💪",
        "🦾",
        "🦵",
        "🦿",
        "🦶",
        "🦷",
        "👅",
        "👄",
        "🫦",
        "💋",
        "🩸",
      ]
    : // engelse
      [
        "want",
        "have",
        "should",
        "not",
        "no",
        "do",
        "make",
        "say",
        "think",
        "the",
        "a",
        "an",
        "every",
        "none",
      ];

export const getPredefinedWords = (amount: number, language: "nl" | "en") => {
  console.log("getPredefinedWords", language, amount);
  const words: string[] = [];
  while (words.length < amount) {
    const sampledWord = sample(predefinedWords(language));
    if (sampledWord) {
      words.push(sampledWord);
    }
  }
  return words;
};
