import axios from "axios";
import { random } from "lodash";
import wash from "washyourmouthoutwithsoap";

export const textSearch = async (query: string, language: "en" | "nl") => {
  if (wash.check("en", query) || wash.check("nl", query)) {
    query = "internet";
  }

  const response = await axios.get("https://duckduckgo.mot.studio/", {
    params: {
      q: query,
      lang: language === "nl" ? "nl-nl" : "en-en",
    },
  });

  console.log(response.data);

  const texts: string[] = response.data.text.map(
    (item: any) => `${item.title} ${item.body}`
  );
  const images: string[] = response.data.images.map((item: any) => item.image);

  return { texts, images };
};
