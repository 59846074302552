import { useState } from "react";
import styled from "styled-components";
import { colors } from "./constants";

const SlideContainer = styled.div`
  /* width: 900px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  flex: 1;
  height: 1px;
  width: 100%;
  background: ${colors.yellow};
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 32px 0;
  &:hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 40px;
    border-radius: 4px;
    border: 2px solid ${colors.yellow};
    background: ${colors.black};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 24px;
    height: 40px;
    border-radius: 4px;
    border: 2px solid ${colors.yellow};
    background: ${colors.black};
    cursor: pointer;
  }
`;

const SliderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  color: white;
  font-size: 20px;
`;

const SliderButton = styled.button``;
const Arrow = styled.img`
  width: 70px;
  height: 60px;
  margin-left: 32px;
  cursor: pointer;
`;
const OuterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 124px;
  border-top: 1px solid ${colors.blue};
  display: flex;
  align-items: center;
  padding: 14px;
  background: black;
  z-index: 99;
  border-radius: 0 0 20px 20px;

  &.done {
    position: static;
    border-top: none;
    img {
      display: none;
    }
  }
`;

const InnerContainer = styled.div`
  flex: 1;
  align-items: center;
`;

export const SliderStep: React.FC<{
  triggerNextStep?: Function;
  onChange: (value: number) => void;
  minLabel: () => string;
  maxLabel: () => string;
}> = ({ triggerNextStep, onChange, minLabel, maxLabel }) => {
  const [value, setValue] = useState(0.01);
  const [moved, setMoved] = useState(false);
  const [done, setDone] = useState(false);
  return (
    <OuterContainer className={done ? "done" : ""}>
      <SlideContainer>
        <InnerContainer>
          <Slider
            type="range"
            min={1}
            max={100}
            step={1}
            value={value}
            onChange={(e) => {
              setMoved(true);
              setValue(parseFloat(e.target.value));
              onChange(parseFloat(e.target.value));
            }}
          />
          <SlideContainer>
            <SliderLabel>{minLabel()}</SliderLabel>

            <SliderLabel>{maxLabel()}</SliderLabel>
          </SlideContainer>
        </InnerContainer>
        <Arrow
          src="/img/arrow.png"
          onClick={() => {
            triggerNextStep && triggerNextStep();
            setDone(true);
          }}
        />
      </SlideContainer>
    </OuterContainer>
  );
};
