import styled from "styled-components";
import { colors } from "./constants";

export const LayoutContainer = styled.div`
  background: ${colors.black};
`;

export const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 24px;
  right: 24px;
  transition: bottom 0.4s;
  &.focused {
    bottom: 280px;
  }
  &.hidden {
    bottom: -820px;
  }
`;
export const Scene = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: #66f; */
  &.lastStep {
    height: 33%;
    width: 50%;
    overflow: hidden;
  }
`;
export const SceneContainer = styled(FullScreenContainer)`
  z-index: -1;
  filter: url(#pixelate);
`;

export const Overlay = styled(FullScreenContainer)`
  background: ${colors.black};
`;

export const CanvasMessageContainer = styled.div`
  position: absolute;
  bottom: 0px;
  height: 80px;
  display: flex;
  padding: 20px;
  font-size: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  background: white;
  justify-content: space-between;
  align-items: center;
  button {
    font-size: 30px;
  }
`;

export const EditorContainer = styled.div`
  position: relative;
  transition: bottom 0.4s ease-in-out;
  bottom: 0px;
  &.move-up {
    bottom: 420px;
  }
`;

export const ReloadButton = styled.img`
  position: fixed;
  z-index: 10;
  top: 32px;
  right: 32px;
  width: 32px;
  cursor: pointer;
`;
