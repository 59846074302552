import ChatBot from "react-simple-chatbot";
import { ChatContainer } from "./Components";
import { getSteps } from "./steps";
import { colors } from "./constants";
import { ThemeProvider } from "styled-components";

const theme = {
  background: colors.black,
  // fontFamily: "Helvetica Neue",
  headerBgColor: colors.blue,
  headerFontColor: colors.white,
  headerFontSize: "15px",
  headerFontFamily: "monospace",
  botBubbleColor: colors.black,
  botFontColor: colors.white,
  userBubbleColor: colors.black,
  userFontColor: colors.white,
};

export function Chat(
  enableCanvas: boolean,
  canvasDone: boolean,
  onTextAnswer: (answer: string, language: "en" | "nl") => Promise<void>,
  onResolution: (resolution: number) => 0 | undefined,
  setEnableCanvas: (enable: boolean) => void,
  handleDone: () => void,
  onAdsAmount: (adsAmount: number) => void,
  onCensorshipAmount: (censorshipAmount: number) => void,
  setLanguage: (language: "en" | "nl") => void,
  setCanvasDone: (done: boolean) => void
) {
  return (
    <ThemeProvider theme={theme}>
      <ChatContainer className={enableCanvas && !canvasDone ? "hidden" : ""}>
        <ChatBot
          style={{
            width: "100%",
            height: "820px",
            overflow: "visible",
            border: `1px solid ${colors.blue}`,
            borderRadius: "20px",
            // background: colors.black,
          }}
          customStyle={{
            background: "none",
            boxShadow: "none",
            padding: 0,
            display: "block",
          }}
          footerStyle={{
            background: "transparent",
            borderColor: colors.blue,
          }}
          botAvatar={"/img/bot.png"}
          userAvatar={"/img/user.png"}
          headerTitle={"Internew"}
          steps={getSteps({
            onTextAnswer,
            onResolution,
            setEnableCanvas,
            handleDone,
            onAdsAmount,
            onCensorshipAmount,
            setLanguage,
            setCanvasDone,
          })}
        />
      </ChatContainer>
    </ThemeProvider>
  );
}
